'use client';

import {
  Box,
  Container,
  SimpleGrid,
  Stack,
  Text,
  Flex,
  Icon,
  Image,
} from '@chakra-ui/react';

import {
  FaDiscord,
  FaYoutube,
  FaTelegramPlane,
  FaTwitter,
} from 'react-icons/fa';
import { AiFillInstagram } from 'react-icons/ai';
import logo from '../../image/logo.png';
import img from '../../image/ugs.webp';

const ListHeader = ({ children }) => {
  return (
    <Text fontWeight={'500'} fontSize={'lg'} mb={2} color={'white'}>
      {children}
    </Text>
  );
};

export default function Footer() {
  return (
    <Box>
      <Container as={Stack} maxW={'6xl'} py={10}>
        <SimpleGrid columns={{ base: 1, sm: 2, md: 5 }} spacing={2}>
          <Stack align={'flex-start'}>
            <ListHeader>Company</ListHeader>
            <Box
              as="a"
              href={'/'}
              color={'whiteAlpha.600'}
              transition={'0.2s ease'}
              _hover={{ color: '#fffa2b', transition: '0.2s ease' }}
            >
              Home
            </Box>
            <Box
              as="a"
              href={'/roadmap'}
              color={'whiteAlpha.600'}
              transition={'0.2s ease'}
              _hover={{ color: '#fffa2b', transition: '0.2s ease' }}
            >
              Roadmap
            </Box>
            <Box
              as="a"
              href={'/contact'}
              color={'whiteAlpha.600'}
              transition={'0.2s ease'}
              _hover={{ color: '#fffa2b', transition: '0.2s ease' }}
            >
              Contact
            </Box>
          </Stack>
          <Stack align={'flex-start'}>
            <ListHeader>Whitepaper</ListHeader>
            <Box
              as="a"
              href={'https://unipolycoin.com/WhitepaperTr.pdf'}
              color={'whiteAlpha.600'}
              transition={'0.2s ease'}
              _hover={{ color: '#fffa2b', transition: '0.2s ease' }}
            >
              Turkish
            </Box>
            <Box
              as="a"
              href={'https://unipolycoin.com/WhitepaperEn.pdf'}
              color={'whiteAlpha.600'}
              transition={'0.2s ease'}
              _hover={{ color: '#fffa2b', transition: '0.2s ease' }}
            >
              English
            </Box>
          </Stack>

          <Stack align={'flex-start'}>
            <ListHeader>Unipoly Game Studio</ListHeader>
            <Box as="a" href={'https://unipolygames.com/'} mt={2}>
              <Image src={img} h={45} w={170} loading="lazy" />
            </Box>
          </Stack>

          <Stack align={'flex-start'} p={'0px 5px'}>
            <ListHeader>Follow Us</ListHeader>
            <Stack direction={'row'} justify={'space-evenl'}>
              <Box
                as="a"
                href={'https://www.instagram.com/unipolycoin/'}
                color={'whiteAlpha.600'}
              >
                <Icon
                  as={AiFillInstagram}
                  w={7}
                  h={7}
                  transition={'0.2s ease'}
                  _hover={{ color: '#fffa2b', transition: '0.2s ease' }}
                />
              </Box>
              <Box
                as="a"
                href={'https://twitter.com/unipolycoin'}
                color={'whiteAlpha.600'}
              >
                <Icon
                  as={FaTwitter}
                  w={7}
                  h={7}
                  transition={'0.2s ease'}
                  _hover={{ color: '#fffa2b', transition: '0.2s ease' }}
                />
              </Box>
              <Box
                as="a"
                href={'https://www.youtube.com/@unipolycoin'}
                color={'whiteAlpha.600'}
              >
                <Icon
                  as={FaYoutube}
                  w={7}
                  h={7}
                  transition={'0.2s ease'}
                  _hover={{ color: '#fffa2b', transition: '0.2s ease' }}
                />
              </Box>
              <Box
                as="a"
                href={'https://t.me/unipolycoin'}
                color={'whiteAlpha.600'}
              >
                <Icon
                  as={FaTelegramPlane}
                  w={7}
                  h={7}
                  transition={'0.2s ease'}
                  _hover={{ color: '#fffa2b', transition: '0.2s ease' }}
                />
              </Box>
              <Box
                as="a"
                href={'https://discord.com/invite/69QndCcb'}
                color={'whiteAlpha.600'}
              >
                <Icon
                  as={FaDiscord}
                  w={7}
                  h={7}
                  transition={'0.2s ease'}
                  _hover={{ color: '#fffa2b', transition: '0.2s ease' }}
                />
              </Box>
            </Stack>
          </Stack>
          <Stack align={'flex-start'}>
            <ListHeader>Legal</ListHeader>
            <Box
              as="a"
              href={'/legal/privacy'}
              color={'whiteAlpha.600'}
              transition={'0.2s ease'}
              _hover={{ color: '#fffa2b', transition: '0.2s ease' }}
            >
              Privacy Policy
            </Box>
            <Box
              as="a"
              href={'/legal/terms'}
              color={'whiteAlpha.600'}
              transition={'0.2s ease'}
              _hover={{ color: '#fffa2b', transition: '0.2s ease' }}
            >
              Terms Of Use
            </Box>
            <Box
              as="a"
              href={'/legal/commercial'}
              color={'whiteAlpha.600'}
              transition={'0.2s ease'}
              _hover={{ color: '#fffa2b', transition: '0.2s ease' }}
            >
              Commercial Communication Notice
            </Box>
            <Box
              as="a"
              href={'/legal/items'}
              color={'whiteAlpha.600'}
              transition={'0.2s ease'}
              _hover={{ color: '#fffa2b', transition: '0.2s ease' }}
            >
              Special Conditions for Use and Conversion of Items
            </Box>

            <Box
              as="a"
              href={'/legal/risk'}
              color={'whiteAlpha.600'}
              transition={'0.2s ease'}
              _hover={{ color: '#fffa2b', transition: '0.2s ease' }}
            >
              Unipoly Coin Risk Statement
            </Box>
          </Stack>
        </SimpleGrid>
      </Container>
      <Box py={10}>
        <Flex
          align={'center'}
          _before={{
            content: '""',
            borderBottom: '1px solid',
            borderColor: '#fffa2b ',
            flexGrow: 1,
            mr: 8,
          }}
          _after={{
            content: '""',
            borderBottom: '1px solid',
            borderColor: '#fffa2b ',
            flexGrow: 1,
            ml: 20,
          }}
        >
          <Image src={logo} w={'200px'} ml={'50px'} loading="lazy"></Image>
        </Flex>
        <Text
          pt={6}
          fontSize={'sm'}
          textAlign={'center'}
          fontWeight={'400'}
          color={'gray.500'}
        >
          © All right reserved 2023.
        </Text>
        <Text
          pt={1}
          fontSize={'sm'}
          textAlign={'center'}
          fontWeight={'400'}
          color={'gray.500'}
          _hover={{ color: '#fffa2b' }}
        >
          <a href="https://unipolygames.com/">Unipoly Game Studio </a>
        </Text>
      </Box>
    </Box>
  );
}
