import React from 'react';
import {
  Card,
  Container,
  Heading,
  Stack,
  Text,
  Box,
  Button,
  Image,
} from '@chakra-ui/react';
import './buybox.css';
import bg from '../../image/bg/5.webp';
import toobit from '../../image/sponsors/Toobit.webp';
import mexc from '../../image/sponsors/full-mexc-logo.png';
import coinw from '../../image/sponsors/coinw.webp';
import rocket from '../../image/buycardimage/rocket.webp';
import rocketastronaut from '../../image/buycardimage/rocketastronaut.webp';
import saturn from '../../image/buycardimage/saturn.webp';
import cloud from '../../image/buycardimage/cloud.webp';
import blocksafu1 from '../../image/sponsors/blocksafu1.png';

import {
  MouseParallaxContainer,
  MouseParallaxChild,
} from 'react-parallax-mouse';
import { useInView, animated } from '@react-spring/web';

export default function Buybox() {
  const [ref, springs] = useInView(
    () => ({
      from: {
        opacity: 0,
        x: -100,
      },
      to: {
        opacity: 1,
        x: 0,
      },
    }),
    { once: true }
  );
  return (
    <Box bgImage={{ base: 'none', md: bg }} backgroundSize={'cover'}>
      <animated.div style={springs}>
        <Container maxW={'82rem'}>
          <MouseParallaxContainer globalFactorX={0.1} globalFactorY={0.1}>
            <Box display={{ base: 'none', md: 'inline' }}>
              <MouseParallaxChild factorX={0.2} factorY={0.2}>
                <img
                  src={rocketastronaut}
                  loading="lazy"
                  alt=""
                  className="rocketastronaut"
                />
              </MouseParallaxChild>
              <MouseParallaxChild factorX={-0.2} factorY={0.4}>
                <img src={saturn} alt="" className="saturn" />
              </MouseParallaxChild>
              <MouseParallaxChild factorX={0.2} factorY={0.2}>
                <img src={rocket} alt="" loading="lazy" className="rocket" />
              </MouseParallaxChild>{' '}
              <MouseParallaxChild factorX={0.2} factorY={0.4}>
                <img src={cloud} alt="" className="cloud1" />
              </MouseParallaxChild>
              <MouseParallaxChild factorX={0.8} factorY={0.4}>
                <img src={cloud} alt="" className="cloud2" />
              </MouseParallaxChild>
            </Box>
            <Stack
              direction={'column'}
              pt={'5%'}
              pb={'5%'}
              justify={'center'}
              align={'center'}
            >
              <Button
                py={50}
                href="https://blocksafu.com/audit/0x23d7Ff057c696fEE679c60cEf61Fee6614218f04"
                as={'a'}
                background={'transparent'}
                border={'solid 1px'}
                borderColor={'transparent'}
                _hover={{
                  borderColor: '#f6c617',
                  background: 'rgba(255,255,255,0.3)',
                }}
              >
                <Image src={blocksafu1} maxW={'150px'}></Image>
              </Button>
              <Heading
                mb={'36px'}
                style={{ zIndex: 1 }}
                fontSize={'30px'}
                textAlign={'center'}
                color={'white'}
              >
                Invest in UniPolyCoin
              </Heading>
              <Text
                mb={'36px'}
                style={{ zIndex: 1 }}
                maxW={'650px'}
                textAlign={'center'}
                color={'white'}
                fontWeight={'normal'}
              >
                Seize the opportunity to be part of gaming ecosystem powered by
                UniPolyCoin.
                <br />
                With an active user base of 2 million players in RaidField 2 and
                counting, our digital currency is at the heart of an intense
                gaming economy.
              </Text>
              <Card
                ref={ref}
                border={'solid 1px'}
                w={{
                  base: '90vw',
                  sm: '90vw',
                  md: '650px',
                  lg: '650px',
                  xl: '650px',
                  '2xl': '650px',
                }}
                h={{
                  base: '420px',
                  sm: '320px',
                  md: '320px',
                  lg: '330px',
                  xl: '350px',
                  '2xl': '350px',
                }}
                borderRadius={'20px'}
                borderColor={'transparent'}
                transition={'all .3s ease'}
                style={{
                  zIndex: 1,
                  background:
                    'radial-gradient(circle, rgba(71, 71, 71, 0.30024509803921573) 0%, rgba(0, 0, 0, 0.5243347338935574) 91.1%)',
                }}
                _hover={{
                  borderColor: '#fffa2b',
                  transition: '0.3s ease',
                }}
              >
                <Stack
                  flexDirection={['column', 'row']}
                  justify={'space-evenly'}
                  align={'center'}
                  h={'100%'}
                  w={'100%'}
                >
                  <Text
                    fontWeight="bold"
                    color="white"
                    maxW={'325px'}
                    lineHeight={1.2}
                    textAlign={'center'}
                    fontSize={{
                      base: '30px',
                      sm: '30px',
                      md: '30px',
                      lg: '30px',
                      xl: '40px',
                      '2xl': '40px',
                    }}
                  >
                    Where to get <Text color={'#fffa2b'}>$UNP</Text>
                    <Text
                      fontWeight={'normal'}
                      color={'gray.100'}
                      fontSize={'15px'}
                      paddingTop={'30px'}
                    >
                      We continuously look for new partners to join us in
                      offering you new ways to acquire $UNP.
                    </Text>
                  </Text>
                  <Stack flexDirection={['column']}>
                    <Button
                      py={50}
                      href="https://www.mexc.com/tr-TR/exchange/UNP_USDT?_from=market"
                      as={'a'}
                      background={'transparent'}
                      border={'solid 1px'}
                      borderColor={'transparent'}
                      _hover={{
                        borderColor: '#f6c617',
                        background: 'rgba(255,255,255,0.3)',
                      }}
                    >
                      <Image src={mexc} maxW={'150px'}></Image>
                    </Button>
                    <Button
                      py={50}
                      href="https://www.coinw.com/frontSpot/spottrade?symbol=1518"
                      as={'a'}
                      background={'transparent'}
                      border={'solid 1px'}
                      borderColor={'transparent'}
                      _hover={{
                        borderColor: '#7650ff',
                        background: 'rgba(255,255,255,0.3)',
                      }}
                    >
                      <Image src={coinw} maxW={'150px'}></Image>
                    </Button>
                    <Button
                      py={50}
                      href="https://www.toobit.com/tr-TR/spot/UNP_USDT"
                      as={'a'}
                      background={'transparent'}
                      border={'solid 1px'}
                      borderColor={'transparent'}
                      _hover={{
                        borderColor: '#2b64fb',
                        background: 'rgba(255,255,255,0.3)',
                      }}
                    >
                      <Image src={toobit} maxW={'150px'}></Image>
                    </Button>
                  </Stack>
                </Stack>
              </Card>
            </Stack>
          </MouseParallaxContainer>
        </Container>
      </animated.div>
    </Box>
  );
}
